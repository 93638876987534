.dagger-cta {
  padding: var(--pageMarginMedium) var(--pageMargin);
  background-color: var(--neonColor);
  color: #000;
  display: flex;
  gap: var(--doublePadding);
  flex-direction: column;
  background-position: 100% 80%;
  background-repeat: no-repeat;
  background-size: cover;
}

.dagger-cta h2 {
  flex: initial;
  font-size: var(--daggerFs2);
  margin: 0;
}

.dagger-cta-desc {
  flex: initial;
  width: 40vmax;
  font-size: var(--daggerFs3);
  display: flex;
  flex-direction: column;
  gap: var(--doublePadding);
  align-items: flex-start;
}

/* Media queries */
@media screen and (max-width: 860px) {
  .dagger-cta {
    position: relative;
    padding: var(--pageMarginMedium) var(--pageMargin)
      calc(var(--pageMarginMedium) * 3);
  }

  .dagger-cta-desc {
    width: 70%;
  }

  .dagger-cta .dagger-outline-btn {
    display: block;
    font-size: 1rem;
    padding: var(--halfPadding) var(--standardPadding);
    margin: 0;
    width: calc(100% - 2 * var(--pageMargin));
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: calc(1.5 * var(--pageMargin));
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-cta .dagger-outline-btn {
    width: auto;
  }
}
