.dagger-full-video-player {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 99999999999;
}

.dagger-full-video-player .video-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleY(0.5);
  opacity: 0;
  transition: all 0.3s ease;
  max-height: 75vh;
}

.dagger-full-video-player .video-container.active {
  transform: translate(-50%, -50%);
  opacity: 1;
}

.dagger-full-video-player .video-container.landscape {
  padding-bottom: 40%;
  height: 0;
  width: 80%;
}

.dagger-full-video-player .video-container video {
  width: 100%;
  object-fit: cover;
}

.dagger-full-video-player .video-container video {
  width: 100%;
  object-fit: contain;
  max-height: 75vh;
}

.full-video-close-btn {
  position: absolute;
  top: calc(0.75 * var(--pageMargin));
  right: calc(1.25 * var(--pageMargin));
  margin: 0;
  padding: 0;
}

.full-video-close-btn svg {
  width: 2rem;
  height: auto;
}

.full-video-close-btn svg path {
  stroke: #fff;
}
