.dagger-awards {
  padding: calc(1 * var(--doublePadding)) var(--pageMargin);
  background-color: var(--tanColor);
  color: #000;
  display: flex;
  justify-content: center;
  gap: var(--standardPadding);
}

.dagger-award {
  flex: 1;
  text-align: center;
  display: flex;
  gap: var(--quarterPadding);
  flex-direction: column;
  max-width: 35rem;
}

.dagger-award img {
  height: 3.2rem;
  width: auto;
  object-fit: contain;
  display: block;
}

.dagger-award h5 {
  margin: 0 auto;
  font-size: var(--daggerFs1);
}

/* Mobile */
@media screen and (max-width: 860px) and (orientation: portrait) {
  .dagger-awards {
    flex-direction: column;
    align-items: center;
  }

  .dagger-award img {
    height: 2.2rem;
  }
}
