.dagger-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.42);
  z-index: 999999999;
}

.dagger-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 75%;
  transform: translate(-50%, -49%) scale(0.9);
  background-color: #fff;
  color: #000;
  opacity: 0;
  transition: all 0.2s ease;
  overflow-y: auto;
}

.dagger-modal-content.active {
  transform: translate(-50%, -50%);
  opacity: 1;
}

.dagger-modal-content .dagger-close-btn {
  position: absolute;
  top: var(--standardPadding);
  right: var(--standardPadding);
}

.dagger-modal-content .dagger-close-btn svg {
  width: 1.5rem;
  height: auto;
}

.dagger-modal.inverted .dagger-modal-content {
  color: #fff;
  background-color: #000;
}

.dagger-modal.inverted .dagger-modal-content .dagger-close-btn svg path {
  stroke: #fff;
}

/* Media queries */
@media screen and (max-width: 860px) {
  .dagger-modal-content {
    width: 90%;
    height: auto;
    max-height: 80%;
  }
}
