.dagger-footer {
  background-color: #000;
  margin: 0;
}

.dagger-footer a:hover {
  color: var(--neonColor);
}

.dagger-footer-content-top {
  width: 100%;
  margin: 0 auto;
  display: flex;
  color: #fff;
  padding: var(--pageMargin);
}

.dagger-footer-content-top-menu {
  width: 36%;
  flex: initial;
}

.dagger-footer-content-top ul {
  display: flex;
  flex-direction: column;
  gap: var(--doublePadding);
}

.dagger-footer-content-top ul li {
  font-size: var(--daggerFs4);
}

.dagger-footer-content-top ul li:hover {
  cursor: pointer;
  opacity: 0.8;
}

.dagger-footer-content-top-extra-group {
  display: flex;
  flex: 1;
}

.dagger-footer-content-top-extras {
  flex: initial;
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: var(--daggerFs2);
}

.dagger-footer-content h2 {
  margin: 0;
  font-weight: normal;
  font-size: var(--daggerFs3);
}

.dagger-footer-content-top-extras-bottom {
  margin: var(--standardPadding) 0 0;
  display: flex;
  flex-direction: column;
  gap: var(--standardPadding);
}

.dagger-footer-content-top-extras-bottom-social {
  display: flex;
  margin: var(--quarterPadding) 0 0;
  gap: var(--standardPadding);
}

.dagger-footer-content-top-extras-bottom-social img {
  width: 2rem;
  height: 2rem;
}

.dagger-footer-content-top-extras-bottom-social a:hover img {
  filter: invert(20%) sepia(100%) saturate(2.5) hue-rotate(10deg);
}

.dagger-footer-wordmark {
  margin: calc(3 * var(--doublePadding)) auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 98%;
  height: 27%;
  padding: var(--doublePadding) var(--standardPadding);
}

.dagger-footer-wordmark svg {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.dagger-footer-wordmark svg path {
  fill: var(--darkGreyColor);
}

.dagger-footer-legal {
  background-color: var(--darkGreyColor);
  color: #000;
  display: flex;
  padding: var(--standardPadding) var(--doublePadding);
  font-size: var(--daggerFs2);
  display: flex;
}

.dagger-footer-legal-links {
  margin: 0 0 0 auto;
  display: flex;
  gap: var(--standardPadding);
  text-transform: uppercase;
  text-decoration: underline;
}

/* Media queuries */
@media screen and (max-width: 860px) {
  .dagger-footer-content-top {
    width: 100vw;
    overflow: hidden;
    flex-direction: row;
  }

  .dagger-footer-content h2 {
    font-size: var(--daggerFs3);
    white-space: nowrap;
  }

  .dagger-footer-content-top ul {
    width: 100%;
    text-align: left;
  }

  .dagger-footer-content-top ul li {
    font-size: var(--daggerFs3);
    line-height: var(--daggerFs2);
  }

  .dagger-footer-content-top-extra-group {
    flex-direction: column;
    margin: 0 0 0 calc(2 * var(--pageMargin));
  }

  .dagger-footer-content-top-extras {
    font-size: var(--daggerFs2);
    text-align: left;
    margin: 0;
    width: 100%;
  }

  .dagger-footer-content-top-extras:nth-of-type(2) {
    margin: calc(2 * var(--pageMargin)) 0 0;
  }

  .dagger-footer-content-top-extras-bottom.mobile-only {
    margin: calc(2 * var(--pageMargin)) 0 0;
  }

  .dagger-footer-content-top-extras-bottom-social {
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }

  .dagger-footer-content-top-extras-bottom-social a img {
    width: 1.3rem;
    height: auto;
  }

  .dagger-footer-wordmark {
    margin: 0;
    width: 100%;
  }

  .dagger-footer-legal {
    font-size: var(--daggerFs1);
    flex-direction: column;
    text-align: center;
  }

  .dagger-footer-legal-links {
    justify-content: center;
    margin: var(--standardPadding) 0 0;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-footer-content-top {
    flex-direction: row;
  }

  .dagger-footer-content-top ul {
    text-align: left;
  }

  .dagger-footer-content-top-extras {
    margin: 0;
  }
}
