.dagger-flex-container {
  padding: var(--pageMargin);
  background-color: #000;
  color: #fff;
}

.dagger-flex-container h6 {
  margin: 0 0 var(--doublePadding);
  font-size: var(--daggerFs2);
  font-family: var(--bodyFont);
  text-transform: none;
}

.dagger-flex-items {
  display: grid;
  gap: var(--standardPadding);
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.dagger-flex-item-copy {
  font-size: var(--daggerFs1);
}

.dagger-flex-item-image img {
  max-width: 100%;
}
