.dagger-work-hero {
  padding: var(--pageMarginWide) var(--pageMargin) var(--pageMargin);
  display: flex;
  gap: var(--pageMargin);
}

.dagger-work-hero-meta {
  width: 40%;
  flex: initial;
}

.dagger-work-hero-meta h1 {
  font-size: var(--daggerFs5);
}

.dagger-work-hero-meta p {
  font-size: var(--daggerFs2);
}

.dagger-work-hero-feat {
  flex: initial;
  width: 55%;
  overflow: hidden;
  margin: 0 0 0 auto;
  position: relative;
}

.dagger-work-hero-feat img {
  width: 100%;
  display: block;
  margin: 0 0 var(--standardPadding) auto;
  transition: all 0.3s ease;
}

.dagger-work-hero-feat h6 {
  font-size: var(--daggerFs1);
}

.dagger-work-hero-feat h5 {
  font-size: var(--daggerFs2);
  text-transform: none;
  margin: 0;
}

.dagger-work-hero-feat:hover {
  cursor: pointer;
  opacity: 0.8;
}

.dagger-work-hero-feat:hover img {
  transform: scale(1.01);
}
