.dagger-capabilities {
  padding: var(--pageMargin);
  color: var(--tanColor);
}

.dagger-capabilities h1 {
  font-size: var(--daggerFs5);
}

.dagger-capabilities .capability {
  border-bottom: 1px solid #fff;
  margin: 0 0 var(--standardPadding);
  padding: 0 0 var(--standardPadding);
}

.dagger-capabilities .capability h3 {
  margin: 0;
  font-size: var(--daggerFs4);
  text-transform: none;
}

.dagger-capabilities .capability-heading {
  display: flex;
  align-items: center;
}

.dagger-capabilities .capability-heading button {
  margin: 0 0 0 auto;
}

.dagger-capabilities .capability-heading button svg {
  width: 2rem;
  transition: all 0.3s ease;
}

.dagger-capabilities .capability-heading button svg path {
  stroke: #fff;
}

.dagger-capabilities .capability-heading button.expanded svg {
  transform: rotate(-45deg);
}

.dagger-capabilities .capability-heading:hover {
  cursor: pointer;
  opacity: 0.8;
}

.dagger-capabilities .capability:last-of-type {
  margin: 0 0 var(--doublePadding);
}

.dagger-capabilities .capability-copy {
  margin: var(--standardPadding) 0 0;
  font-size: var(--daggerFs2);
}

.dagger-capabilities .dagger-outline-btn {
  border-color: #fff;
}

/* Media queries */
@media screen and (max-width: 860px) {
  .dagger-capabilities .dagger-outline-btn {
    display: block;
    font-size: 1rem;
    padding: var(--halfPadding) var(--standardPadding);
    margin: var(--doublePadding) 0 0;
    width: 100%;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-capabilities .dagger-outline-btn {
    margin: 0 auto;
    width: auto;
  }
}
