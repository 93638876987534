.dagger-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 999999999999;
  perspective: 400px;
  perspective-origin: 50% 50%;
}

.dagger-loader-overlay .dagger-spinning-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20rem;
  width: 20rem;
  margin: 0;
}

.dagger-loader-slide {
  position: fixed;
  top: 100vh;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--neonColor);
  z-index: -1;
}

.dagger-loader-tiles {
  position: fixed;
  top: 100vh;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.dagger-loader-tile {
  top: 0;
  height: 20vh;
  width: 100vw;
  background-color: var(--neonColor);
  left: -100vw;
  position: absolute;
}

.dagger-loader-tile:nth-child(2) {
  top: 20vh;
}

.dagger-loader-tile:nth-child(3) {
  top: 40vh;
}

.dagger-loader-tile:nth-child(4) {
  top: 60vh;
}

.dagger-loader-tile:nth-child(5) {
  top: 80vh;
}

@media screen and (max-width: 860px) {
  .dagger-loader-overlay .dagger-spinning-logo {
    height: 20vmax;
    width: 20vmax;
  }
}
