.dagger-single-work-item {
  padding: var(--pageMargin);
  display: flex;
  overflow: hidden;
  position: relative;
  gap: var(--pageMarginWide);
}

.dagger-single-work-item-image {
  width: 100%;
  height: 0;
  padding-bottom: 56.4%;
  overflow: hidden;
  position: relative;
}

.dagger-single-work-item-image img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: all 0.3s ease;
}

.dagger-single-work-item h6 {
  font-size: var(--daggerFs1) !important;
  margin: var(--standardPadding) 0 var(--halfPadding) !important;
}

.dagger-single-work-item h5 {
  font-size: var(--daggerFs1);
  text-transform: none;
  font-family: var(--bodyFont);
  margin: 0;
}

.dagger-single-work-item-content {
  position: relative;
  width: 75%;
  margin: 0 0 0 auto;
}

.dagger-single-work-item-content:hover {
  cursor: pointer;
  opacity: 0.8;
}

.dagger-single-work-item-content:hover img {
  transform: scale(1.01);
}

.dagger-single-work-item-logo {
  width: 70vmax;
  position: absolute;
  bottom: 12%;
  left: 0;
  transform: translate(-40%);
  opacity: 0.4;
}

/* Alternate */
.dagger-single-work-item:nth-child(2n) .dagger-single-work-item-content {
  margin: 0 auto 0 0;
}

/* Media queries */
@media screen and (max-width: 860px) and (orientation: portrait) {
  .dagger-single-work-item-content {
    width: 100%;
  }
}
