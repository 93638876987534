.dagger-positions {
  padding: var(--pageMarginWide) var(--pageMargin) var(--pageMarginWide);
  display: flex;
  gap: var(--pageMargin);
  background-color: #fff;
  color: #000;
  position: relative;
  overflow: hidden;
}

.dagger-positions-meta {
  width: 50%;
  flex: initial;
  position: relative;
  z-index: 3;
}

.dagger-positions-meta h1 {
  font-size: var(--daggerFs5);
  padding: 0 20vmax 0 0;
}

.dagger-positions-meta p {
  font-size: var(--daggerFs2);
  margin: var(--standardPadding) 0;
}

.dagger-positions-meta-btns {
  margin: var(--doublePadding) 0;
  display: flex;
  gap: var(--standardPadding);
}

.dagger-positions-list {
  width: 50%;
  flex: initial;
  padding: var(--standardPadding) 0 0;
}

.dagger-positions-group {
  margin: 0 0 var(--standardPadding);
  padding: 0 0 var(--standardPadding);
  border-bottom: 1px solid #000;
}

.dagger-positions-group-label {
  display: flex;
  gap: var(--standardPadding);
}

.dagger-positions-group-label h5 {
  margin: 0;
  font-size: var(--daggerFs2);
}

.dagger-positions-group-label svg {
  margin: 0 0 0 auto;
  padding: 0;
  transition: all 0.2s ease;
  transform-origin: 50% 50%;
}

.dagger-positions-group-label:hover {
  cursor: pointer;
  opacity: 0.6;
}

.dagger-positions-group.expanded .dagger-positions-group-label svg {
  transform: rotate(-180deg);
}

.dagger-positions-group-roles {
  font-size: var(--daggerFs1);
  padding: var(--standardPadding) 0 0;
  display: flex;
  gap: var(--halfPadding);
  flex-direction: column;
}

.dagger-positions-logo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60vmax;
  transform: translate(-42%, 5%);
  z-index: 1;
}

.dagger-positions-role {
  color: var(--darkGreyColor);
  font-weight: bold;
}

.dagger-positions-role:hover {
  cursor: pointer;
  opacity: 0.8;
}

.dagger-selected-job {
  padding: var(--pageMargin);
  color: #000;
}

.dagger-selected-job-header {
  display: flex;
  margin: var(--standardPadding) 0;
  align-items: center;
}

.dagger-selected-job-header svg {
  height: 5rem;
  width: auto;
}

.dagger-selected-job-header svg path {
  fill: #000;
}

.dagger-selected-job-tl {
  margin: 0 0 0 auto;
  max-width: 50%;
  text-align: right;
}

.dagger-selected-job-tl h3 {
  font-size: var(--daggerFs3);
  margin: 0;
}

.dagger-selected-job-tl h4 {
  font-size: var(--daggerFs1);
  margin: 0;
}

.dagger-selected-job .dagger-outline-btn {
  border-color: #000;
  margin: var(--halfPadding) 0 0;
  display: inline-block;
}

.dagger-selected-job-desc {
  font-size: var(--daggerFs1);
  margin: var(--standardPadding) 0;
}

/* Media queries */
@media screen and (max-width: 860px) {
  .dagger-positions {
    flex-direction: column;
  }

  .dagger-positions-meta {
    width: 100%;
  }

  .dagger-positions-meta h1 {
    padding: 0;
    margin: 0;
  }

  .dagger-positions-meta-btns {
    margin: var(--standardPadding) 0;
  }

  .dagger-positions-meta-btns a {
    display: block;
    font-size: 1rem;
    padding: calc(2 * var(--quarterPadding)) var(--standardPadding);
    width: 50%;
    text-align: center;
  }

  .dagger-positions-list {
    width: 100%;
    padding: 0;
  }

  .dagger-positions-logo {
    display: none;
  }

  .dagger-selected-job-header {
    flex-direction: column;
    gap: var(--standardPadding);
  }

  .dagger-selected-job-header svg {
    height: 3rem;
  }

  .dagger-selected-job-tl {
    max-width: 100%;
    width: 100%;
    text-align: center;
  }

  .dagger-selected-job .dagger-outline-btn {
    font-size: 1rem;
    display: block;
    width: 100%;
    margin: var(--standardPadding) 0 0;
    padding: calc(2 * var(--quarterPadding)) var(--standardPadding);
    text-align: center;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-selected-job .dagger-outline-btn {
    margin: var(--standardPadding) auto 0;
    width: auto;
    display: inline-block;
  }
}
