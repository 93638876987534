@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap");

* {
  box-sizing: border-box;
}

:root {
  /* --backgroundColor: #272727; */
  /* --backgroundColor: #2b2b2b; */
  --backgroundColor: #2c2b2c;
  --neonColor: #f4ff7d;
  --tanColor: #e7e3da;
  --textColor: #fff;
  --greyColor: #e7e3da;
  --darkGreyColor: #3c3c3c;
  --doublePadding: clamp(1rem, 1.5vw + 2rem, 3rem);
  --standardPadding: clamp(0.5rem, 0.75vw + 1rem, 1.5rem);
  --halfPadding: clamp(0.25rem, 0.37vw + 1rem, 0.75rem);
  --quarterPadding: clamp(0.125rem, 0.17vw + 1rem, 0.375rem);
  --pageMargin: clamp(3.5rem, 3vw + 1.25rem, 4.5rem);
  --pageMarginMedium: calc(1.5 * var(--pageMargin));
  --pageMarginWide: calc(3 * var(--pageMargin));
  --defaultShadow: rgba(34, 34, 34, 0.1);
  --headerFont: "Roboto Condensed";
  --bodyFont: "Roboto";

  /* Custom sizing for dagger components */
  --daggerFs1: 0.8rem !important;
  --daggerFs1: clamp(0.8rem, 0.5vw + 1rem, 1.1rem) !important;
  --daggerFs2: 1rem !important;
  --daggerFs2: clamp(1rem, 1vw + 1rem, 1.5rem) !important;
  --daggerFs3: 1.6rem !important;
  --daggerFs3: clamp(1.6rem, 2vw + 1rem, 2.05rem) !important;
  --daggerFs4: 1.8rem !important;
  --daggerFs4: clamp(1.8rem, 2vw + 1rem, 2.1rem) !important;
  --daggerFs5: 3rem !important;
  --daggerFs5: clamp(3rem, 4vw + 1rem, 3.6rem) !important;
  --daggerFs6: 4rem !important;
  --daggerFs6: clamp(4rem, 5vw + 1rem, 4.5rem) !important;
}

/* Mobile */
.desktop-only {
  display: initial !important;
}

.mobile-only {
  display: none !important;
}

@media screen and (max-width: 860px) {
  :root {
    --doublePadding: clamp(1rem, 1.5vw + 2rem, 3rem);
    --standardPadding: clamp(0.5rem, 0.75vw + 1rem, 1.5rem);
    --halfPadding: clamp(0.25rem, 0.37vw + 1rem, 0.75rem);
    --quarterPadding: clamp(0.125rem, 0.17vw + 0.1rem, 0.375rem);
    --pageMargin: clamp(1.6rem, 2vw + 1rem, 2.5rem);
    --pageMarginMedium: calc(1.5 * var(--pageMargin));
    --pageMarginWide: calc(3 * var(--pageMargin));
    --daggerFs1: 0.9rem !important;
    --daggerFs1: clamp(0.9rem, 0.5vw + 0.5rem, 1rem) !important;
    --daggerFs2: 1rem !important;
    --daggerFs2: clamp(1rem, 1vw + 0.5rem, 1.2rem) !important;
    --daggerFs3: 1.2rem !important;
    --daggerFs3: clamp(1.2rem, 1vw + 1rem, 1.4rem) !important;
    --daggerFs4: 1.5rem !important;
    --daggerFs4: clamp(1.5rem, 2vw + 1rem, 2rem) !important;
    --daggerFs5: 2.5rem !important;
    --daggerFs5: clamp(2.5rem, 3vw + 1rem, 3rem) !important;
    --daggerFs6: 3rem !important;
    --daggerFs6: clamp(3rem, 4vw + 1rem, 3.5rem) !important;
  }

  .mobile-only {
    display: inherit !important;
  }

  .desktop-only {
    display: none !important;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  :root {
    --daggerFs1: 0.7rem !important;
    --daggerFs2: 1rem !important;
    --daggerFs3: 1.1rem !important;
    --daggerFs4: 1.5rem !important;
    --daggerFs5: 2.3rem !important;
    --daggerFs6: 2.6rem !important;
  }
}

#dagger-root,
body,
html {
  height: 100%;
}

.dagger-root {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  color: var(--textColor);
  overflow: hidden;
  flex-wrap: nowrap;
  align-items: stretch;
}

body {
  font-family: var(--bodyFont), "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--backgroundColor);
  color: var(--textColor);
}

/* Resets */
a {
  text-decoration: none;
  color: inherit;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

button,
textarea,
input {
  text-rendering: inherit;
  font: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  max-width: 100%;
  white-space: nowrap;
}

input:not(:read-only):focus {
  border-color: var(--brandPrimaryColor) !important;
}

button:disabled {
  opacity: 0.4;
}

button:not(:disabled):hover {
  cursor: pointer;
  opacity: 0.8;
}

/* End resets */

/* Header font sizing */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: 400;
  font-family: var(--headerFont), var(--bodyFont), "sans-serif";
  text-transform: uppercase;
}

h1 {
  font-size: 1.53rem;
  font-size: clamp(1.853rem, 2vw + 1rem, 2.5rem);
  margin: 0 0 var(--doublePadding);
}

h2 {
  font-size: 1.56rem;
  font-size: clamp(1.56rem, 1.75vw + 1rem, 2.14rem);
  margin: 0 0 var(--doublePadding);
}

h3 {
  font-size: 1.34rem;
  font-size: clamp(1.34rem, 1.5vw + 1rem, 1.78rem);
  margin: 0 0 var(--standardPadding);
}

h4 {
  font-size: 1.12rem;
  font-size: clamp(1.12rem, 1.25vw + 1rem, 1.48rem);
  margin: 0 0 var(--standardPadding);
}

h5 {
  font-size: 1rem;
  font-size: clamp(1rem, 1vw + 1rem, 1.22rem);
  margin: 0 0 var(--halfPadding);
}

h6 {
  font-weight: 500;
  font-size: 0.8rem;
  font-size: clamp(0.8rem, 0.5vw + 1rem, 0.95rem);
  margin: 0 0 var(--halfPadding);
}

/* End font sizing */

/* Colors */
.background-black {
  background-color: #000;
}

/* main */
.dagger-app {
  width: 100%;
  height: 100%;
}

/* Pages */
.dagger-page {
  width: 100%;
  height: 100%;
}

.dagger-page-content {
  padding: 10vh 10vw;
  margin: 0 auto;
  min-height: 100vh;
}

.dagger-page-content.auto {
  min-height: 0;
}

/* Buttons */
.dagger-outline-btn {
  border: 1px solid #000;
  border-radius: 25px;
  padding: var(--quarterPadding) var(--doublePadding);
  text-transform: uppercase;
  font-size: clamp(0.75rem, 0.6vw + 0.2rem, 1.063rem);
}

/* Grid */
.dagger-grid {
  --_cols: 4;
  --_gap: var(--gap, var(--standardPadding));
  --_min: var(--min, 200px);
  --_gap-count: calc(var(--_cols) - 1);
  --_total-gap-width: calc(var(--_gap-count) * var(--_gap));
  --_grid-item--max-width: calc(
    (100% - var(--_total-gap-width)) / var(--_cols)
  );
  display: grid;
  grid-gap: var(--_gap);
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--_min), var(--_grid-item--max-width)), 1fr)
  );
  grid-template-rows: auto;
  grid-auto-rows: auto;
  padding: 0;
  margin: var(--doublePadding) 0;
}

.dagger-grid li {
  border: 1px solid var(--neonColor);
  border-radius: 10px;
  padding: var(--standardPadding);
  background-color: #000;
  color: var(--neonColor);
  transition: all 0.3s ease;
  min-height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dagger-grid li h3 {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.dagger-grid li:hover {
  cursor: pointer;
  background-color: #222;
}

/* Misc */
.dagger-form {
  padding: calc(3 * var(--doublePadding)) 0;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

.dagger-form input {
  border: 1px solid var(--neonColor);
  font-size: 1rem;
  padding: var(--quarterPadding) var(--standardPadding);
  border-radius: 10px;
  color: #fff;
  display: block;
  margin: var(--standardPadding) auto;
}

.dagger-form .dagger-outline-btn {
  background-color: var(--neonColor);
  color: #000;
  border: none;
}

.dagger-error-copy {
  text-align: center;
  font-size: 1rem;
  color: rgb(245, 38, 86);
  margin: var(--standardPadding) auto;
}

.dagger-client-welcome {
  padding: var(--pageMarginWide) var(--pageMargin) var(--pageMargin);
}

.dagger-client-welcome h5 {
  font-family: var(--bodyFont);
  text-transform: none;
}

/* 16:9 container */
.dagger-169 {
  width: 100%;
  height: 0;
  padding-bottom: 56.4%;
  overflow: hidden;
  margin: 0 0 var(--standardPadding);
  position: relative;
}

.dagger-169 img {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-position: 50% 20%;
  object-fit: cover;
}

.dagger-169 video {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
}

/* Glider */
.glide-controls button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.glide-controls button:nth-of-type(1) {
  left: calc(-1 * var(--pageMargin) / 2);
}

.glide-controls button:nth-of-type(2) {
  right: calc(-1 * var(--pageMargin) / 2);
}

/* Hint */
.dagger-item-hint {
  position: absolute;
  bottom: 6rem;
  left: 1rem;
  background-color: #000;
  z-index: 5;
  padding: 0.5rem;
  font-size: var(--daggerFs1);
  transition: all 0.3s ease 0.1s;
  opacity: 0;
  transform: translateY(10px);
}

.dagger-single-work-item-content:hover .dagger-item-hint,
.dagger-work-hero-feat:hover .dagger-item-hint,
.dagger-work-item:hover .dagger-item-hint {
  opacity: 1;
  transform: none;
}

/* Error */
.dagger-error {
  padding: var(--pageMargin);
  color: #fff;
  font-size: var(--daggerFs1);
}

.dagger-error svg {
  height: 4rem;
  width: auto;
  margin: var(--standardPadding) 0;
}

.dagger-error svg path {
  fill: var(--neonColor);
}

.dagger-error p {
  margin: var(--standardPadding) 0;
}
