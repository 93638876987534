.dagger-case-study {
  padding: var(--pageMarginWide) var(--pageMargin) var(--pageMargin);
  min-height: 50vh;
}

.dagger-case-study h1 {
  font-size: var(--daggerFs5);
  margin: 0 0 var(--halfPadding);
  padding: 0 var(--pageMargin) 0 0;
}

.dagger-case-study h6 {
  font-size: var(--daggerFs1);
  margin: 0 0 var(--doublePadding);
}

.dagger-case-study .dagger-work-slider {
  margin: var(--doublePadding) 0 0 calc(-1 * var(--pageMargin));
  width: calc(100% + 2 * var(--pageMargin));
}
