.dagger-image-slider {
  padding: var(--pageMargin) 0;
  color: #fff;
}

.dagger-image-slider.with-padding {
  padding: var(--pageMargin);
}

.dagger-image-items {
  width: 100%;
}

.dagger-image-item {
  width: 45%;
  position: relative;
}

.dagger-image-item-image {
  width: 100%;
  height: 30rem;
  position: relative;
}

.dagger-image-item-image img {
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

@media screen and (max-width: 860px) {
  .dagger-image-item-image {
    height: 30vh;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-image-item-image {
    height: 40vh;
  }
}
