.dagger-three-col-container {
  padding: var(--pageMargin);
  color: var(--tanColor);
}

.dagger-three-col {
  display: flex;
  gap: var(--standardPadding);
  padding: calc(var(--pageMargin) / 2) 0;
}

.dagger-three-col .col {
  flex: initial;
  width: 33.3%;
}

.dagger-three-col .col h4 {
  font-size: var(--daggerFs2);
}

.dagger-three-col .col p {
  font-size: var(--daggerFs4);
  margin: 0;
  padding: 0 40% 0 0;
}

.dagger-three-col .col p span {
  color: var(--neonColor);
}

.dagger-three-col .col:nth-of-type(2) {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.dagger-three-col .col:nth-of-type(2) p,
.dagger-three-col .col:nth-of-type(2) h4 {
  padding: 0 15%;
}

.dagger-three-col .col:nth-of-type(3) p,
.dagger-three-col .col:nth-of-type(3) h4 {
  padding: 0 15% 0 10%;
}

/* Media queries */
@media screen and (max-width: 1199px) {
  .dagger-three-col .col p {
    font-size: var(--daggerFs3);
    padding: 0;
  }

  .dagger-three-col .col:nth-of-type(2) p,
  .dagger-three-col .col:nth-of-type(2) h4 {
    padding: 0 5% 0 10%;
  }

  .dagger-three-col .col:nth-of-type(3) p,
  .dagger-three-col .col:nth-of-type(3) h4 {
    padding: 0 5%;
  }
}

@media screen and (max-width: 860px) {
  .dagger-three-col {
    flex-direction: column;
    padding: var(--quarterPadding) 0 var(--pageMargin);
    gap: var(--doublePadding);
  }

  .dagger-three-col .col {
    width: 100%;
    border: none !important;
  }

  .dagger-three-col .col h4,
  .dagger-three-col .col p {
    padding: 0 !important;
  }
}
