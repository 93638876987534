.dagger-logo-animation-container {
  position: relative;
  height: 80vh;
  width: 100%;
}

.logo-animation-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.logo-animation {
  position: absolute;
  width: 80%;
  height: 0;
  overflow: hidden;
  padding-bottom: 45%;
  margin: 0 auto;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-animation video {
  max-width: 80%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.logo-animation .upcoming-animation {
  z-index: 1;
}

/* Media queries */
@media screen and (max-width: 860px) {
  .dagger-logo-animation-container {
    height: 0;
    padding-bottom: 56%;
  }

  .logo-animation-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .logo-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: none;
  }

  .logo-animation video {
    width: 100%;
    max-width: none;
    transform: none;
    top: 0;
    left: 0;
  }
}
