.dagger-client-slider {
  padding: var(--pageMargin);
  color: #fff;
}

.dagger-client-slider.bg-white {
  background-color: #fff;
  color: #000;
  padding: var(--pageMargin) 0;
}

.dagger-client-slider.bg-white h6 {
  font-size: var(--daggerFs5);
  margin: 0 0 var(--pageMargin) var(--pageMargin);
}

.dagger-client-items {
  margin: var(--doublePadding) 0;
  width: 100%;
}

.dagger-client-slider h6 {
  font-size: var(--daggerFs2);
}

.dagger-client-item {
  margin: 0 calc(1 * var(--pageMargin));
  height: 8vh;
  width: 10vw;
  overflow: hidden;
  position: relative;
}

.dagger-client-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}

/* Mobile */
@media screen and (max-width: 860px) {
  .dagger-client-item {
    height: 5vh;
    width: 30vw;
  }

  .dagger-client-slider.bg-white h6 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-client-item {
    height: 15vh;
    width: 20vw;
  }
}
