.dagger-text-image-block {
  background-color: var(--tanColor);
  color: #000;
  padding: var(--pageMargin);
}

.dagger-text-image-block h2 {
  margin: 0;
  font-size: var(--daggerFs5);
  position: relative;
  z-index: 2;
}

.dagger-text-image-block h3 {
  margin: 0;
  font-size: var(--daggerFs5);
  text-transform: none;
  font-weight: normal;
  font-family: var(--bodyFont);
  width: 68%;
}

.dagger-text-image-block-main {
  position: relative;
}

.dagger-text-image-block-main p {
  width: 70%;
  margin: var(--pageMargin) 0 var(--pageMargin) auto;
  font-size: var(--daggerFs2);
}

.dagger-text-image-block-main p > p {
  width: 100%;
  margin: 0;
}

.dagger-text-image-block-main img {
  position: absolute;
  bottom: calc(-2 * var(--pageMargin));
  left: calc(-1 * var(--pageMargin));
  z-index: 0;
}

/* Media queries */
@media screen and (max-width: 1460px) {
  .dagger-text-image-block h3 {
    width: 60%;
  }
}

@media screen and (max-width: 860px) {
  .dagger-text-image-block h2,
  .dagger-text-image-block h3 {
    font-size: 2rem;
    width: 100%;
  }

  .dagger-text-image-block-main p {
    width: 100%;
    font-size: 1.05rem;
    position: relative;
    z-index: 2;
  }

  .dagger-text-image-block-main img {
    width: 60%;
    opacity: 0.6;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-text-image-block-main img {
    width: 25%;
  }
}
