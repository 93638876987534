.dagger-people-grid {
  padding: var(--pageMargin);
  background-color: #000;
}

.dagger-people-grid h1 {
  font-size: var(--daggerFs5);
}

.dagger-people-grid ul {
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fit, minmax(max(300px, calc(33% - 2rem)), 1fr)); */
  column-gap: 2rem;
  row-gap: 2rem;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.dagger-people-grid ul li {
  width: calc(33.33% - 2rem);
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: var(--halfPadding);
}
 

.dagger-people-grid ul li svg path {
  stroke: #fff;
}

.dagger-people-grid ul li:hover {
  cursor: pointer;
}

.dagger-people-grid ul li:hover img {
  transform: scale(1.01);
}

.dagger-people-grid ul li h5,
.dagger-people-grid ul li h6 {
  margin: 0;
  padding: 0;
  text-transform: none;
}

.dagger-people-grid ul li h5 {
  font-size: var(--daggerFs3);
}

.dagger-people-grid ul li h6 {
  font-size: var(--daggerFs1);
}

.dagger-person-image {
  width: 100%;
  height: 0;
  padding: 0 0 100%;
  position: relative;
  overflow: hidden;
}

.dagger-people-grid ul li img {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  object-position: 50% 0%;
  object-fit: cover;
  transition: all 0.4s ease;
}

/* Selected */
.dagger-selected-person {
  display: flex;
  gap: var(--standardPadding);
  height: 100%;
}

.dagger-selected-left {
  width: 40%;
  flex: initial;
  background-color: #000;
  padding: var(--pageMargin);
  color: #fff;
}

.dagger-selected-left img {
  width: 100%;
  margin: var(--standardPadding) auto;
  max-height: 50vh;
}

.dagger-selected-left h2 {
  margin: 0;
  font-size: var(--daggerFs3);
  text-transform: none;
}

.dagger-selected-left h5 {
  margin: var(--quarterPadding) 0 0;
  font-size: var(--daggerFs1);
  text-transform: none;
}

.dagger-selected-right {
  display: flex;
  align-items: center;
  padding: var(--pageMargin);
  font-size: var(--daggerFs1);
  flex: initial;
  width: 60%;
}

/* Media queries */
@media screen and (max-width: 860px) {
  .dagger-selected-person {
    flex-direction: column;
    gap: 0;
  }

  .dagger-selected-left {
    width: 100%;
    padding: var(--standardPadding);
  }

  .dagger-selected-right {
    width: 100%;
    padding: var(--standardPadding);
  }

  .dagger-people-grid .dagger-modal-content .dagger-close-btn svg path {
    stroke: #fff;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-selected-person {
    flex-direction: row;
  }
  .dagger-people-grid .dagger-modal-content .dagger-close-btn svg path {
    stroke: #000;
  }

  .dagger-selected-right {
    font-size: 0.8rem;
    padding: calc(2 * var(--pageMargin)) var(--standardPadding);
  }

  .dagger-selected-left img {
    object-fit: contain;
  }
}
