.dagger-video-scroll {
  background-color: #000;
  color: #fff;
  width: 100vw;
}

.dagger-video-scroll h1 {
  position: absolute;
  top: 6%;
  left: 50%;
  text-transform: uppercase;
  font-size: 8rem;
  line-height: 8rem;
  text-align: center;
  z-index: 9999;
  width: min-content;
  white-space: break-space;
  margin: 0 auto;
  transform: translateX(-50%);
}

.dagger-video-scroll-video {
  width: 100%;
  height: 0;
  margin: 0 auto;
  position: relative;
  padding-bottom: 56.2%;
}

.video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.video-play-icon svg {
  width: 6rem;
  height: 6rem;
}

.video-play-icon svg path:first-of-type {
  opacity: 0.65;
}

.dagger-video-scroll-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: contain;
}

.dagger-video-scroll-video:hover {
  cursor: pointer;
  opacity: 0.9;
}

.dagger-video-scroll-video:hover .video-play-icon {
  opacity: 1;
}
