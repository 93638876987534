.dagger-video-loop {
  background-color: #000;
  color: #fff;
  width: 100vw;
  height: 85vh;
  overflow: hidden;
  position: relative;
}

.dagger-video-loop-video {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: absolute;
}

.dagger-video-loop-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 80%;
}

@media screen and (max-width: 860px) and (orientation: portrait) {
  .dagger-video-loop {
    height: auto;
  }

  .dagger-video-loop-video {
    height: 0;
    padding-bottom: 56.2%;
    position: relative;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-video-loop-video video {
    object-position: 50% 70%;
  }
}

@media screen and (min-aspect-ratio: 2) and (max-aspect-ratio: 2.2) {
  .dagger-video-loop {
    height: 90vh;
  }
}

@media screen and (min-aspect-ratio: 2.2) and (max-aspect-ratio: 3) {
  .dagger-video-loop {
    height: 120vh;
  }
}

@media screen and (min-aspect-ratio: 3) and (max-aspect-ratio: 3.8) {
  .dagger-video-loop {
    height: 150vh;
  }
}

@media screen and (min-aspect-ratio: 3.8) and (max-aspect-ratio: 10) {
  .dagger-video-loop {
    height: 85vmax;
  }
}
