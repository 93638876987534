.dagger-text-scroll {
  background-color: #000;
  color: var(--tanColor);
  padding: var(--pageMargin);
  transition: all 0.6s ease;
}

.dagger-text-scroll.inverted {
  background-color: var(--tanColor);
  color: #000;
}

.dagger-text-scroll.inverted .dagger-text-scroll-copy button {
  border-color: #000;
}

.dagger-text-scroll.theme-white-black {
  background-color: #000;
  color: #fff;
}

.dagger-text-scroll h2 {
  margin: 0;
  font-size: var(--daggerFs5);
}

.dagger-text-scroll-copy {
  width: 70%;
  margin: var(--pageMargin) 0 0 auto;
}

.dagger-text-scroll-copy p {
  font-size: var(--daggerFs2);
  margin: var(--standardPadding) 0;
}

.dagger-text-scroll-copy button {
  margin: var(--standardPadding) 0 0;
  border-color: var(--tanColor);
  transition: all 0.1s ease;
}

/* Media queries */
@media screen and (max-width: 1460px) {
  .dagger-text-scroll h2 {
    width: 60%;
  }
}

@media screen and (max-width: 860px) {
  .dagger-text-scroll h2 {
    font-size: 2rem;
    width: 100%;
  }

  .dagger-text-scroll-copy {
    width: 100%;
  }

  .dagger-text-scroll-copy p {
    font-size: 1.05rem;
  }

  .dagger-text-scroll-copy button {
    display: block;
    font-size: 1rem;
    padding: var(--halfPadding) var(--standardPadding);
    margin: var(--doublePadding) 0 var(--standardPadding);
    width: 100%;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-text-scroll-copy button {
    width: auto;
    margin: var(--doublePadding) auto var(--standardPadding);
  }
}
