.dagger-work-slider {
  padding: var(--pageMargin);
  color: #fff;
}

.dagger-work-items {
  width: 100%;
}

.dagger-work-slider h6 {
  font-size: var(--daggerFs2) !important;
  margin: 0 0 var(--pageMargin) 0 !important;
}

.dagger-work-item {
  width: 45%;
  transition: all 0.2s ease;
  transform: scale(0.9);
  opacity: 0.4;
  position: relative;
  z-index: 2;
}

.dagger-work-item.glide__slide--active {
  transform: none;
  opacity: 1;
}

.dagger-work-item.selected {
  transform: none;
  opacity: 1;
}

.dagger-work-item:hover {
  cursor: pointer;
  opacity: 0.8;
}

.dagger-work-item:hover .dagger-work-item-image img {
  transform: scale(1.01);
}

.dagger-work-item:only-child {
  margin: 0 0 0 calc(6 * var(--doublePadding));
}

.dagger-work-item-image {
  width: 100%;
  height: 0;
  padding-bottom: 56.1%;
  overflow: hidden;
  margin: 0 0 var(--standardPadding);
  position: relative;
}

.dagger-work-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
}

.dagger-work-item h6 {
  font-size: var(--daggerFs1) !important;
  margin: 0 0 var(--standardPadding) !important;
}

.dagger-work-item h5 {
  font-size: var(--daggerFs1);
  text-transform: none;
  font-family: var(--bodyFont);
  margin: 0;
}

.dagger-work-slider .dagger-outline-btn {
  border-color: #fff;
  margin: var(--doublePadding) 0 0 0 !important;
}

.dagger-work-slider .glide-controls button {
  transform: translateY(-140%);
}

/* Media queries */
@media screen and (max-width: 860px) {
  .dagger-work-item h6 {
    font-size: 0.8rem !important;
  }
  .dagger-work-item h5 {
    font-size: 0.7rem !important;
  }

  .dagger-work-slider .dagger-outline-btn {
    display: block;
    font-size: 1rem;
    padding: var(--halfPadding) var(--standardPadding);
    margin: var(--doublePadding) 0 0;
    width: 100%;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-work-slider .dagger-outline-btn {
    width: auto;
    margin: var(--doublePadding) auto 0 !important;
  }
}
