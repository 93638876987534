.dagger-multi-content-picker {
  padding: var(--pageMargin);
  color: #000;
  background-color: #fff;
}

.dagger-multi-content-picker h4 {
  font-size: var(--daggerFs2);
}

.dagger-multi-content-picker-content {
  display: flex;
  gap: var(--doublePadding);
}

.dagger-multi-content-picker-options {
  width: 50%;
  flex: initial;
}

.dagger-multi-content-picker ul {
  display: flex;
  flex-direction: column;
  gap: var(--standardPadding);
}

.dagger-multi-content-picker ul li {
  font-size: var(--daggerFs3);
}

.dagger-multi-content-picker ul li:not(.selected):hover {
  cursor: pointer;
  opacity: 0.8;
}

.dagger-multi-content-picker ul li.selected {
  color: var(--darkGreyColor);
  padding: 0 0 0 var(--standardPadding);
}

.dagger-multi-content-picker-selected {
  width: 70%;
  margin: var(--standardPadding) 0 0;
  padding: 0 0 0 var(--standardPadding);
  font-size: var(--daggerFs2);
  position: relative;
  color: #000;
}

.dagger-multi-content-picker-selected span {
  color: var(--darkGreyColor);
  font-weight: bold;
}

.dagger-multi-content-picker svg path {
  stroke: #000;
}

.dagger-multi-content-picker-image {
  flex: initial;
  width: 50%;
  height: 50vh;
  margin: 0 0 0 auto;
  text-align: right;
  overflow: hidden;
}

.dagger-multi-content-picker-image img {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  margin: 0;
  display: block;
}

/* Media queries */
@media screen and (max-width: 860px) {
  .dagger-multi-content-picker-content {
    flex-direction: column;
    gap: 0;
  }

  .dagger-multi-content-picker-options {
    width: 100%;
  }

  .dagger-multi-content-picker-selected {
    width: 100%;
    padding: 0 0 0 var(--quarterPadding);
  }

  .dagger-multi-content-picker-image {
    width: 100%;
    height: 30vh;
    margin: var(--standardPadding) auto;
    text-align: center;
  }

  .dagger-multi-content-picker-image img {
    margin: 0 auto;
  }
}
