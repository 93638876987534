.dagger-case-study-results {
  margin: var(--doublePadding) 0;
  display: flex;
  gap: calc(2 * var(--doublePadding));
}

.dagger-case-study-results-container h5 {
  font-size: var(--daggerFs2);
}

.dagger-case-study-result {
  position: relative;
  flex: 1;
  max-width: 25%;
  text-align: center;
}

.dagger-case-study-result h2 {
  margin: 0;
  font-size: var(--daggerFs6);
}

.dagger-case-study-result:not(:last-child):after {
  content: "";
  position: absolute;
  right: calc(-2 * var(--standardPadding));
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #fff;
}

/* Media queries */
@media screen and (max-width: 860px) {
  .dagger-case-study-results-container {
    margin: var(--doublePadding) 0 0;
  }

  .dagger-case-study-results-container h5 {
    text-align: center;
  }

  .dagger-case-study-results {
    flex-direction: column;
    gap: var(--standardPadding);
  }

  .dagger-case-study-result:not(:last-child):after {
    display: none;
  }

  .dagger-case-study-result {
    max-width: 100%;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-case-study-results {
    flex-direction: row;
  }
}
