.dagger-text-hero {
  padding: var(--pageMarginWide) var(--pageMargin) var(--pageMargin);
  background-color: #000;
  color: #fff;
  position: relative;
  overflow: hidden;
}

.dagger-text-hero h1 {
  margin: 0 0 var(--doublePadding);
  font-size: var(--daggerFs5);
  width: 60vmax;
}

.dagger-text-hero h2 {
  margin: 0 0 var(--doublePadding);
  font-size: var(--daggerFs4);
  width: 50vmax;
  text-transform: none;
}

.dagger-text-hero-copy {
  font-size: var(--daggerFs2);
  width: 65vmax;
}

.dagger-text-hero-copy p {
  margin: var(--doublePadding) 0;
}

.dagger-text-hero-logo {
  position: absolute;
  top: 20%;
  right: 0;
  width: 80vmax;
  opacity: 0.4;
  transform: translateX(40%);
}

/* Media queries */
@media screen and (max-width: 860px) {
  .dagger-text-hero h1 {
    width: auto;
  }

  .dagger-text-hero h2 {
    width: auto;
  }

  .dagger-text-hero-copy {
    width: auto;
  }

  .dagger-text-hero-logo {
    display: none;
  }
}
