.dagger-text-block {
  padding: var(--pageMargin);
  background-color: #000;
  color: #fff;
  position: relative;
  overflow: hidden;
}

.dagger-text-block h6 {
  margin: 0 0 var(--doublePadding);
  font-size: var(--daggerFs2);
  font-family: var(--bodyFont);
  text-transform: none;
}

.dagger-text-block-copy {
  font-size: var(--daggerFs1);
}

.dagger-text-block-copy p {
  margin: var(--doublePadding) 0 0;
}

.dagger-text-block-copy a {
  color: var(--neonColor);
}

.dagger-text-block-copy ul {
  margin: var(--standardPadding) 0;
  display: flex;
  gap: var(--halfPadding);
  flex-direction: column;
}

.dagger-text-block:first-of-type {
  padding: var(--pageMarginWide) var(--pageMargin) var(--pageMargin);
}
