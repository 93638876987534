.dagger-people-list {
  background-color: var(--backgroundColor);
  color: #fff;
  padding: var(--pageMargin);
}

.dagger-people-list h2 {
  margin: 0;
  font-size: var(--daggerFs5);
}

.dagger-people-list ul {
  margin: var(--pageMargin) 0 0;
  display: flex;
  flex-direction: column;
  gap: calc(2 * var(--pageMargin));
}

.dagger-people-list ul li {
  display: flex;
  gap: var(--pageMarginWide);
  align-items: flex-start;
}

.dagger-people-list ul li .people-image-container {
  width: 45%;
  flex: initial;
  position: relative;
  height: 0;
  padding-bottom: 38%;
}

.dagger-people-list ul li img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0;
}

.dagger-people-list ul li .people-info-container {
  flex: initial;
  width: 55%;
}

.dagger-people-list h4 {
  margin: 0;
  font-size: var(--daggerFs4);
}

.dagger-people-list p {
  margin: var(--pageMargin) 0;
  font-size: var(--daggerFs2);
}

.dagger-people-list ul li:nth-child(2n) {
  flex-direction: row-reverse;
}

/* Media queries */
@media screen and (max-width: 860px) {
  .dagger-people-list h2 {
    font-size: 2rem;
  }

  .dagger-people-list h4 {
    font-size: 1.5rem;
    text-align: center;
  }

  .dagger-people-list p {
    font-size: 1rem;
  }

  .dagger-people-list ul li .people-image-container {
    width: 50%;
    margin: 0 auto;
    padding-bottom: 48%;
  }

  .dagger-people-list ul li .people-info-container {
    width: 100%;
  }
}

@media screen and (max-width: 860px) and (orientation: portrait) {
  .dagger-people-list ul li {
    flex-direction: column;
    gap: var(--pageMargin);
  }

  .dagger-people-list ul li:nth-child(2n) {
    flex-direction: column;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-people-list ul li .people-image-container {
    width: 40%;
    padding-bottom: 35%;
  }

  .dagger-people-list ul li .people-info-container {
    width: 60%;
  }

  .dagger-people-list h4 {
    text-align: left;
  }
}
