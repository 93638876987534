.dagger-spinning-logo {
  width: 25%;
  margin: var(--doublePadding) auto;
}

.dagger-spinning-logo svg {
  width: 100%;
}

.dagger-badge-circle-text {
  transform-origin: 50% 50%;
  animation: spinning 30s linear infinite forwards;
}

.dagger-spinning-logo svg .cls-1 {
  fill: #e7e3da;
}

/* Temp */
.dagger-spinning-logo svg path {
  fill: #e7e3da;
}

@keyframes spinning {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
