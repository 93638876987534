.dagger-experiments-page {
  padding: var(--pageMarginWide) var(--pageMargin);
  background-color: #000;
  color: #fff;
}

.dagger-experiments-page ul {
  display: flex;
  gap: 2rem;
  margin: var(--doublePadding) 0 0;
}

.dagger-experiments-page ul li {
  height: 5vmax;
  width: 5vmax;
  border: 1px solid var(--neonColor);
  position: relative;
  transition: all 0.4s ease;
}

.dagger-experiments-page ul li h6 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dagger-experiments-page ul li:hover,
.dagger-experiments-page ul li.selected {
  background-color: var(--neonColor);
  color: #000;
  cursor: pointer;
}
