.dagger-case-study-hero {
  margin: var(--doublePadding) auto;
  position: relative;
}

.dagger-case-study-hero h2 {
  font-size: var(--daggerFs4);
  text-transform: none;
  margin: var(--doublePadding) 0;
}

.dagger-case-study-hero-copy {
  padding: var(--pageMargin);
  font-size: var(--daggerFs2);
}

.dagger-case-study-hero video {
  width: 100%;
}

.dagger-case-study-hero .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.dagger-case-study-hero .video-play-icon svg {
  width: 6rem;
  height: 6rem;
}

.dagger-case-study-hero .video-play-icon svg path:first-of-type {
  opacity: 0.65;
}

.dagger-case-study-hero:hover .video-play-icon {
  opacity: 1;
}

.dagger-case-study-hero:hover video {
  cursor: pointer;
}
