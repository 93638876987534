.dagger-phone-holder-container {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.dagger-phone-holder {
  position: relative;
  height: 80vh;
  width: 36.8vh;
  max-width: 100%;
}

.dagger-phone-holder .dagger-phone-frame {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  z-index: 4;
  pointer-events: none;
}

.dagger-phone-holder .dagger-phone-border {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 3;
  pointer-events: none;
}

.dagger-phone-content {
  position: absolute;
  top: 1%;
  left: 2.5%;
  width: 95%;
  height: 98%;
  z-index: 1;
  border-radius: 8%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.dagger-phone-content img,
.dagger-phone-content video {
  width: 100%;
  flex: initial;
  height: 77%;
  flex: initial;
  object-fit: contain;
}

.dagger-phone-header {
  background-color: #000;
  flex: initial;
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.dagger-phone-header-status {
  height: 50%;
  flex: initial;
  background-color: #000;
  padding: 7% 0 0 12%;
  font-weight: 500;
  border-bottom: 1px solid #999;
  position: relative;
  font-size: 1.5vmin;
}

.dagger-phone-header-status img {
  position: absolute;
  height: 0.6rem !important;
  width: auto !important;
  top: 55%;
  right: 10%;
  display: block;
  transform: translateY(-50%);
}

.dagger-phone-header-meta {
  flex: initial;
  height: 50%;
  display: flex;
  align-items: center;
  padding: 0 3%;
  justify-content: flex-start;
  gap: 2%;
}

.dagger-phone-header-meta h6 {
  font-size: 1.5vmin !important;
  font-family: var(--bodyFont) !important;
  text-transform: none;
  margin: 0 !important;
}

.dagger-phone-header-meta img {
  height: 75% !important;
  width: auto !important;
  margin: 0;
}

.dagger-phone-header-meta span {
  display: inline-block;
  font-size: 3vmin;
  margin: 0 0 0 auto;
  transform: translateY(calc(-0.4 * var(--daggerFs1)));
}

.dagger-phone-footer {
  height: 12%;
  flex: initial;
  background-color: #000;
  position: relative;
}

.dagger-phone-footer img {
  height: 50% !important;
  width: auto !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Media queries */
@media screen and (min-width: 1500px) and (max-width: 1620px) {
  .dagger-phone-holder {
    height: 49.91vw;
    width: 23vw;
  }
}

@media screen and (min-width: 1268px) and (max-width: 1499px) {
  .dagger-phone-holder {
    height: 49.91vw;
    width: 23vw;
  }
}

@media screen and (min-width: 1160px) and (max-width: 1267px) {
  .dagger-phone-holder {
    height: 56.42vmin;
    width: 26vmin;
  }

  .dagger-phone-header-status {
    padding: 6% 0 0 12%;
    font-size: 1vmin;
  }

  .dagger-phone-header-status img {
    height: 0.5rem !important;
  }

  .dagger-phone-header-meta h6 {
    font-size: 1.2vmin !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1159px) {
  .dagger-phone-holder {
    height: 49.91vmin;
    width: 23vmin;
  }

  .dagger-phone-header-status {
    padding: 6% 0 0 12%;
    font-size: 1vmin;
  }

  .dagger-phone-header-status img {
    height: 0.5rem !important;
  }

  .dagger-phone-header-meta h6 {
    font-size: 1.2vmin !important;
  }
}

@media screen and (min-width: 860px) and (max-width: 999px) {
  .dagger-phone-holder {
    height: 49.91vmin;
    width: 23vmin;
  }

  .dagger-phone-header-status {
    padding: 6% 0 0 12%;
    font-size: 1vmin;
  }

  .dagger-phone-header-status img {
    height: 0.5rem !important;
  }

  .dagger-phone-header-meta h6 {
    font-size: 1.2vmin !important;
  }
}

@media screen and (max-width: 860px) and (orientation: portrait) {
  .dagger-phone-holder {
    height: 80vh;
    width: 36.86vh;
  }

  .dagger-phone-header-status {
    padding: 7% 0 0 12%;
    font-size: 2vmin;
  }

  .dagger-phone-header-status img {
    height: 0.6rem !important;
  }

  .dagger-phone-header-meta h6 {
    font-size: 3vmin !important;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-phone-holder {
    height: 80vh;
    width: 36.86vh;
  }

  .dagger-phone-header-status {
    padding: 6% 0 0 12%;
    font-size: 2.3vmin;
  }

  .dagger-phone-header-status img {
    height: 0.4rem !important;
  }

  .dagger-phone-header-meta h6 {
    font-size: 2.3vmin !important;
  }
}
