.dagger-case-study-solutions {
  margin: var(--doublePadding) 0;
  display: flex;
  gap: calc(2 * var(--doublePadding));
}

.dagger-case-study-solution {
  position: relative;
  flex: 1;
}

.dagger-case-study-solution h5 {
  font-size: var(--daggerFs2);
}

.dagger-case-study-solution p {
  font-size: var(--daggerFs2);
}

.dagger-case-study-solution:not(:last-child):after {
  content: "";
  position: absolute;
  right: calc(-2 * var(--standardPadding));
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #fff;
}

/* Media queries */
@media screen and (max-width: 860px) {
  .dagger-case-study-solutions {
    flex-direction: column;
    gap: 0;
  }

  .dagger-case-study-solution:not(:last-child):after {
    display: none;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-case-study-solutions {
    flex-direction: row;
    gap: var(--standardPadding);
  }
}
