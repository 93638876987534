.dagger-ig {
  margin: var(--doublePadding) 0 0;
  padding: var(--pageMargin);
  background-color: #000;
}

.dagger-ig h2 {
  margin: 0 0 var(--doublePadding);
  font-size: var(--daggerFs2);
  text-align: left;
}

.dagger-ig .dagger-outline-btn {
  border-color: #fff;
}

.dagger-ig-items {
  width: 100%;
  margin: 0 0 var(--doublePadding);
}

.dagger-ig-item {
  width: 20%;
  height: 0;
  padding-bottom: 3%;
  position: relative;
}

.dagger-ig-item video,
.dagger-ig-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
}

.dagger-ig-item:hover {
  cursor: pointer;
}

.dagger-ig-item:hover img {
  transform: scale(1.01);
}

.dagger-ig-selection {
  display: flex;
  gap: var(--pageMargin);
  background-color: #000;
  height: 100%;
  align-items: center;
  padding: var(--pageMargin);
  color: #fff;
}

.dagger-ig-selection-image {
  width: 50%;
  flex: initial;
}

.dagger-ig-selection-image video,
.dagger-ig-selection-image img {
  width: 100%;
  max-height: 70vh;
  margin: 0 auto;
}

.dagger-ig-selection-meta {
  width: 50%;
  flex: initial;
}

.dagger-ig-selection-meta p {
  font-size: var(--daggerFs1);
}

/* Media queries */
@media screen and (max-width: 860px) {
  .dagger-ig .dagger-outline-btn {
    display: block;
    font-size: 1rem;
    padding: var(--halfPadding) var(--standardPadding);
    margin: var(--doublePadding) 0 0;
    width: 100%;
    text-align: center;
  }

  .dagger-ig {
    margin: var(--standardPadding) 0 0;
    padding: var(--pageMargin) var(--pageMargin)
      calc(var(--standardPadding) + var(--pageMargin));
  }

  .dagger-ig-item {
    padding-bottom: 5%;
  }

  .dagger-ig-selection {
    flex-direction: column;
    height: auto;
    padding-top: var(--pageMarginWide);
  }

  .dagger-ig-selection-meta,
  .dagger-ig-selection-image {
    width: 100%;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-ig {
    text-align: center;
  }
  .dagger-ig .dagger-outline-btn {
    width: auto;
    display: inline-block;
    margin: 0 auto;
  }

  .dagger-ig-selection {
    flex-direction: row;
  }

  .dagger-ig-item {
    padding-bottom: 4%;
  }
}
