.dagger-media-container {
  margin: var(--pageMargin) 0;
  display: flex;
  gap: var(--pageMarginMedium);
}

.dagger-media-container-media {
  flex: 1;
  display: flex;
  margin: 0 !important;
  gap: var(--pageMargin);
  align-items: center;
  position: relative;
}

.dagger-media-container-media img {
  width: 60%;
  flex: initial;
}

.dagger-media-copy {
  width: 40%;
  flex: initial;
  font-size: var(--daggerFs2);
}

.dagger-media-container-media img:only-child {
  width: 100%;
}

.dagger-media-copy:only-child {
  width: 100%;
}

.dagger-media-container-media video {
  width: 100%;
}

.dagger-media-container-media .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.dagger-media-container-media .video-play-icon svg {
  width: 6rem;
  height: 6rem;
}

.dagger-media-container-media .video-play-icon svg path:first-of-type {
  opacity: 0.65;
}

.dagger-media-container-media:hover .video-play-icon {
  opacity: 1;
}

.dagger-media-container-media:hover video {
  cursor: pointer;
}

/* Media queries */
@media screen and (max-width: 860px) and (orientation: portrait) {
  .dagger-media-container {
    flex-direction: column;
  }

  .dagger-media-container-media {
    flex-direction: column;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }

  .dagger-media-container-media img {
    width: 100%;
  }

  .dagger-media-copy {
    width: 100%;
  }
}
