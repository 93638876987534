.dagger-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: var(--standardPadding) var(--pageMargin);
  width: 100vw;
  z-index: 99999999;
  transition: all 0.2s ease;
}

.dagger-header a.dagger-top-level,
.dagger-header button.dagger-top-level {
  line-height: 0;
}

.dagger-header.activated {
  background-color: var(--neonColor);
  padding: var(--quarterPadding) var(--pageMargin);
}

.dagger-header.activated .dagger-top-level {
  width: 3rem;
}

.dagger-header.inverted .dagger-top-level svg path,
.dagger-header.activated .dagger-top-level svg path {
  fill: #000;
}

.dagger-header.inverted button.dagger-top-level svg path,
.dagger-header.activated button.dagger-top-level svg path {
  stroke: #000;
}

.dagger-header .dagger-top-level {
  width: 5rem;
  transition: all 0.2s ease;
}

.dagger-header .dagger-top-level.menu-btn {
  text-align: right;
}

.dagger-header .dagger-top-level svg {
  height: 100%;
  width: auto;
  max-width: 100%;
}

.dagger-header svg path {
  fill: var(--textColor);
}

.dagger-header button {
  margin: 0 0 0 auto;
}

.dagger-header-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--neonColor);
}

.dagger-header-menu-close-btn {
  position: absolute;
  top: var(--standardPadding);
  right: var(--pageMargin);
  z-index: 8888;
}

.dagger-header-menu-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: var(--pageMargin);
  left: 0;
  width: 100%;
  height: calc(100% - var(--pageMargin));
  transform: translateY(-10vh) scaleY(0.5);
  opacity: 0;
  transition: all 0.3s ease;
  transform-origin: 50% 0%;
}

.dagger-header-menu-content.active {
  opacity: 1;
  transform: none;
}

.dagger-header-menu-content-top {
  flex: initial;
  width: 60%;
  margin: auto 0;
  margin: auto;
  display: flex;
  color: #000;
}

.dagger-header-menu-content-top ul {
  width: 65%;
  flex: initial;
  display: flex;
  flex-direction: column;
  gap: var(--doublePadding);
}

.dagger-header-menu-content-top ul li {
  font-size: var(--daggerFs4);
}

.dagger-header-menu-content-top ul li:hover {
  cursor: pointer;
  opacity: 0.8;
}

.dagger-header-menu-content-top-extras {
  flex: initial;
  width: 35%;
  display: flex;
  flex-direction: column;
  font-size: var(--daggerFs2);
}

.dagger-header-menu-content-top-extras h2 {
  margin: 0;
  font-weight: normal;
  font-size: var(--daggerFs3);
}

.dagger-header-menu-content-top-extras-bottom {
  margin: auto 0 0;
  display: flex;
  flex-direction: column;
  gap: var(--standardPadding);
  text-transform: uppercase;
  text-decoration: underline;
}

.dagger-header-menu-wordmark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 27%;
  padding: var(--doublePadding) var(--standardPadding);
}

.dagger-header-menu-wordmark svg {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.dagger-header-menu-wordmark svg path {
  fill: var(--greyColor);
}

/* Mobile */
@media screen and (max-width: 860px) {
  .dagger-header .dagger-top-level {
    width: 2.75rem;
  }

  .dagger-header.activated {
    padding: var(--halfPadding) var(--pageMargin);
  }

  .dagger-header.activated .dagger-top-level {
    width: 2.5rem;
  }

  .dagger-main-mark {
    margin: 0 auto 0 0;
  }

  .dagger-main-mark svg {
    width: auto;
  }

  .dagger-header-menu-close-btn svg {
    height: 3rem;
  }

  .dagger-header-menu-content {
    top: var(--pageMarginWide);
    height: calc(100% - var(--pageMarginWide));
  }

  .dagger-header-menu-content-top {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .dagger-header-menu-content-top ul {
    width: 100%;
    align-items: center;
    gap: var(--standardPadding);
  }

  .dagger-header-menu-content-top ul li {
    font-size: var(--daggerFs4);
  }

  .dagger-header-menu-content-top-extras {
    width: 100%;
    font-size: var(--daggerFs2);
    text-align: center;
    margin: var(--doublePadding) 0 0;
  }

  .dagger-header-menu-wordmark {
    padding: 0 0 var(--pageMargin);
    justify-content: flex-end;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .dagger-header-menu-content-top {
    flex-direction: row;
    align-items: flex-start;
  }

  .dagger-header-menu-content-top ul {
    gap: var(--halfPadding);
  }

  .dagger-header-menu-content {
    top: calc(1.75 * var(--pageMargin));
  }

  .dagger-header-menu-content-top-extras {
    margin: 0;
  }

  .dagger-header-menu-content-top-extras p {
    margin: 0.5rem 0;
  }

  .dagger-header-menu-wordmark {
    padding: 0;
  }

  .dagger-header-menu-content.active {
    height: calc(100% - 2.5 * (var(--pageMargin)));
  }
}
